import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [isResetting, setIsResetting] = useState(false);
  const { signIn, signUp, resetPassword } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (isResetting) {
        await resetPassword(email);
        setSuccess('Email de recuperação enviado! Verifique sua caixa de entrada.');
        setIsResetting(false);
      } else if (isLogin) {
        await signIn(email, password);
        navigate('/translate');
      } else {
        await signUp(email, password);
        navigate('/translate');
      }
    } catch (error) {
      if (isResetting) {
        setError('Erro ao enviar email de recuperação. Verifique o email informado.');
      } else {
        setError(isLogin ? 'Falha ao fazer login' : 'Falha ao criar conta');
      }
    }
  };

  const handleResetPassword = () => {
    setIsResetting(true);
    setIsLogin(true);
    setError('');
    setSuccess('');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {isResetting
              ? 'Recuperar Senha'
              : isLogin
                ? 'Entre na sua conta'
                : 'Crie sua conta'}
          </h2>
        </div>

        {!isResetting && (
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => {
                setIsLogin(true);
                setIsResetting(false);
                setError('');
              }}
              className={`px-4 py-2 rounded-lg ${
                isLogin
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-gray-700'
              }`}
            >
              Login
            </button>
            <button
              onClick={() => {
                setIsLogin(false);
                setIsResetting(false);
                setError('');
              }}
              className={`px-4 py-2 rounded-lg ${
                !isLogin
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-gray-700'
              }`}
            >
              Registrar
            </button>
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {error && (
            <div className="text-red-500 text-center">{error}</div>
          )}
          {success && (
            <div className="text-green-500 text-center">{success}</div>
          )}
          <div className="space-y-4">
            <div>
              <input
                type="email"
                required
                className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {!isResetting && (
              <div>
                <input
                  type="password"
                  required
                  className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {isResetting
                ? 'Enviar Email de Recuperação'
                : isLogin
                  ? 'Entrar'
                  : 'Criar Conta'}
            </button>
          </div>
        </form>

        <div className="text-center text-sm text-gray-600">
          {!isResetting && (
            <>
              {isLogin ? (
                <p>
                  Não tem uma conta?{' '}
                  <button
                    onClick={() => {
                      setIsLogin(false);
                      setError('');
                    }}
                    className="text-blue-600 hover:text-blue-500"
                  >
                    Registre-se
                  </button>
                </p>
              ) : (
                <p>
                  Já tem uma conta?{' '}
                  <button
                    onClick={() => {
                      setIsLogin(true);
                      setError('');
                    }}
                    className="text-blue-600 hover:text-blue-500"
                  >
                    Faça login
                  </button>
                </p>
              )}
            </>
          )}
          {isLogin && !isResetting && (
            <p className="mt-2">
              <button
                onClick={handleResetPassword}
                className="text-blue-600 hover:text-blue-500"
              >
                Esqueceu sua senha?
              </button>
            </p>
          )}
          {isResetting && (
            <p className="mt-2">
              <button
                onClick={() => {
                  setIsResetting(false);
                  setError('');
                  setSuccess('');
                }}
                className="text-blue-600 hover:text-blue-500"
              >
                Voltar ao login
              </button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;