import React, { useState } from 'react';

function Disparador() {
  const [formData, setFormData] = useState({
    pixelId: '',
    email: '',
    phone: '',
    contentId: '',
    event: 'CompletePayment',
    value: '',
    currency: 'BRL'
  });

  const [status, setStatus] = useState({ message: '', type: '' });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const mostrarStatus = (mensagem, tipo) => {
    setStatus({ message: mensagem, type: tipo });
  };

  const dispararPixel = () => {
    const { pixelId, email, phone, contentId, event, value, currency } = formData;

    if (!pixelId) {
      mostrarStatus('Pixel ID é obrigatório!', 'error');
      return;
    }

    if (!email || !phone) {
      mostrarStatus('Email e telefone são obrigatórios para CompletePayment!', 'error');
      return;
    }

    if (!contentId) {
      mostrarStatus('Content ID é obrigatório para Video Shopping Ads!', 'error');
      return;
    }

    // Remove script antigo se existir
    const oldScript = document.getElementById('tiktok-pixel');
    if (oldScript) {
      oldScript.remove();
    }

    window.ttq = undefined;

    // Cria e adiciona novo script
    const script = document.createElement('script');
    script.id = 'tiktok-pixel';
    script.innerHTML = `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;
        var ttq=w[t]=w[t]||[];
        ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"];
        ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
        for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
        ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e};
        ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

        ttq.load('${pixelId}');
        ttq.page();
      }(window, document, 'ttq');

      setTimeout(() => {
        if (window.ttq) {
          ttq.identify({
            email: '${email}',
            phone: '${phone}'
          });

          ttq.track('${event}', {
            value: ${value || 0},
            currency: '${currency}',
            content_id: '${contentId}'
          });
          mostrarStatus('Evento disparado com sucesso!', 'success');
        } else {
          mostrarStatus('Erro ao carregar o pixel. Verifique o Pixel ID.', 'error');
        }
      }, 1000);
    `;

    document.body.appendChild(script);
  };

  return (
    <div className="bg-gray-100 p-6">
      <div className="max-w-md mx-auto bg-white rounded-xl shadow-md p-6">
        <h1 className="text-2xl font-bold mb-4 text-gray-800">TikTok Pixel Trigger</h1>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Pixel ID:</label>
            <input
              type="text"
              id="pixelId"
              value={formData.pixelId}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2 border"
              placeholder="Seu Pixel ID"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email:</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2 border"
              placeholder="email@exemplo.com"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Telefone:</label>
            <input
              type="tel"
              id="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2 border"
              placeholder="+5511999999999"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Content ID:</label>
            <input
              type="text"
              id="contentId"
              value={formData.contentId}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2 border"
              placeholder="ID do conteúdo"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Evento:</label>
            <select
              id="event"
              value={formData.event}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2 border"
            >
              <option value="CompletePayment">Complete Payment</option>
              <option value="Purchase">Purchase</option>
              <option value="ViewContent">View Content</option>
              <option value="AddToCart">Add to Cart</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Valor:</label>
            <input
              type="number"
              id="value"
              value={formData.value}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2 border"
              placeholder="Valor"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Moeda:</label>
            <select
              id="currency"
              value={formData.currency}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2 border"
            >
              <option value="BRL">BRL</option>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
            </select>
          </div>

          <div>
            <button
              onClick={dispararPixel}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
            >
              Disparar Pixel
            </button>
          </div>

          {status.message && (
            <div className={`mt-2 p-2 rounded-md ${status.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
              {status.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Disparador;
