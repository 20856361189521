import React, { useState, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../Components/ui/card';
import { Button } from '../../Components/ui/button';
import { Alert, AlertDescription } from '../../Components/ui/alert';
import { Volume2, Download, Sparkles, Target, Zap, Clock, Brain, Mic2, Megaphone } from 'lucide-react';

export default function TextToSpeech() {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState('alloy');
  const [audioUrl, setAudioUrl] = useState('');
  const [error, setError] = useState('');
  const audioRef = useRef(null);
  const MAX_CHARS = 150;

  const handleGenerateAudio = async () => {
    if (!text.trim()) {
      setError('Por favor, insira um texto');
      return;
    }
    if (text.length > MAX_CHARS) {
      setError(`O texto excede o limite de ${MAX_CHARS} caracteres`);
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await fetch('https://api.openai.com/v1/audio/speech', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'tts-1',
          voice: selectedVoice,
          input: text,
        }),
      });
      if (!response.ok) throw new Error('Falha ao gerar áudio');
      const audioBlob = await response.blob();
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
      if (audioRef.current) audioRef.current.load();
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao gerar áudio. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    if (audioUrl) {
      const a = document.createElement('a');
      a.href = audioUrl;
      a.download = 'audio.mp3';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100">
      <div className="max-w-6xl mx-auto p-6 space-y-8">
        {/* Header */}
        <div className="text-center space-y-4">
          <h1 className="text-5xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
            Genius Voice AI
          </h1>
          <p className="text-xl text-gray-700">Transform suas palavras em voz natural com IA</p>

          {/* Benefits Grid */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
            {[
              {
                icon: Brain,
                title: "IA Avançada",
                desc: "Tecnologia state-of-the-art da OpenAI"
              },
              {
                icon: Mic2,
                title: "6 Vozes Únicas",
                desc: "Variedade de timbres e sotaques"
              },
              {
                icon: Zap,
                title: "Ultra Rápido",
                desc: "Geração em segundos"
              },
              {
                icon: Megaphone,
                title: "Alta Qualidade",
                desc: "Áudio cristalino em MP3"
              }
            ].map((benefit, index) => (
              <Card key={index} className="bg-white/80">
                <CardContent className="pt-6 text-center">
                  <benefit.icon className="h-8 w-8 mx-auto text-purple-500 mb-2" />
                  <h3 className="font-semibold mb-1">{benefit.title}</h3>
                  <p className="text-sm text-gray-600">{benefit.desc}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          {/* Input Section */}
          <Card>
            <CardHeader>
              <CardTitle>Criar Áudio</CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Escolha a Voz
                </label>
                <select
                  value={selectedVoice}
                  onChange={(e) => setSelectedVoice(e.target.value)}
                  className="w-full h-10 px-3 border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                >
                  <option value="alloy">Alloy - Neutro</option>
                  <option value="echo">Echo - Masculino</option>
                  <option value="fable">Fable - Britânico</option>
                  <option value="onyx">Onyx - Africano</option>
                  <option value="nova">Nova - Feminino</option>
                  <option value="shimmer">Shimmer - Britânica</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Texto ({text.length}/{MAX_CHARS})
                </label>
                <textarea
                  value={text}
                  onChange={(e) => {
                    if (e.target.value.length <= MAX_CHARS) {
                      setText(e.target.value);
                    }
                  }}
                  maxLength={MAX_CHARS}
                  className="w-full h-32 px-3 py-2 border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="Digite seu texto aqui..."
                />
              </div>

              {error && (
                <Alert variant="destructive">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              <Button
                onClick={handleGenerateAudio}
                disabled={loading}
                className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700"
              >
                {loading ? (
                  <div className="flex items-center justify-center gap-2">
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    Gerando...
                  </div>
                ) : (
                  <>
                    <Volume2 className="mr-2 h-5 w-5" />
                    Gerar Áudio
                  </>
                )}
              </Button>
            </CardContent>
          </Card>

          {/* Preview Section */}
          <Card className="bg-white/90 backdrop-blur-sm shadow-xl">
            <CardHeader>
              <CardTitle>Preview do Áudio</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {audioUrl ? (
                <div className="space-y-4">
                  <div className="bg-purple-50 p-4 rounded-xl">
                    <audio ref={audioRef} controls className="w-full">
                      <source src={audioUrl} type="audio/mpeg" />
                    </audio>
                  </div>
                  <Button
                    onClick={handleDownload}
                    variant="outline"
                    className="w-full border-purple-200 hover:bg-purple-50"
                  >
                    <Download className="mr-2 h-4 w-4" />
                    Baixar MP3
                  </Button>
                </div>
              ) : (
                <div className="text-center py-12">
                  <Volume2 className="h-12 w-12 mx-auto mb-4 text-purple-300" />
                  <p className="text-gray-500">Seu áudio aparecerá aqui</p>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}