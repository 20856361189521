// src/services/openaiService.js

class OpenAIService {
    constructor() {
      this.apiKey = process.env.REACT_APP_OPENAI_API_KEY;
      this.baseUrl = 'https://api.openai.com/v1';
    }

    async generateImage({ prompt, size = '1024x1024', quality = 'standard', style = 'vivid' }) {
      const response = await fetch(`${this.baseUrl}/images/generations`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'dall-e-3',
          prompt,
          n: 1,
          size,
          quality,
          style,
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error?.message || 'Failed to generate image');
      }

      const data = await response.json();
      return data.data[0].url;
    }
  }

  export default new OpenAIService();