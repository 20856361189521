import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBcmxTBH3FGUwuhEF34U_Lt8f56vggI8Pc",
  authDomain: "teste-50557.firebaseapp.com",
  projectId: "teste-50557",
  storageBucket: "teste-50557.appspot.com",
  messagingSenderId: "139834967976",
  appId: "1:139834967976:web:SEU_APP_ID"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); 