import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { LandingPage } from './Pages/Code/Code.jsx';
import Disparador from './Pages/Disparador/Disparador.jsx';
import ElementorCloner from './Pages/Elementor/Elementor.jsx';
import ProductDescriptionGenerator from './Pages/Description-ecom/Description-ecom.jsx';
import Translate from './Pages/Translate/Translate.jsx';
import Geniusvoice from './Pages/Geniusvoice/Geniusvoice.jsx';
import Imgautox from './Pages/ImgAutox/Imgautox.jsx';
import { AuthProvider } from './contexts/AuthContext';
import Login from './Pages/Login/Login.jsx';
import ProtectedRoute from './Components/ProtectedRoute';

// Inicialização do Pixel
const advancedMatching = { };
const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init('3137254639900900', advancedMatching, options);
ReactPixel.pageView();

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/disparador",
    element: <Disparador />,
  },
  {
    path: "/elementor",
    element: <ElementorCloner />,
  },
  {
    path: "/description-ecom",
    element: <ProductDescriptionGenerator />,
  },
  {
    path: "/translate",
    element: <ProtectedRoute><Translate /></ProtectedRoute>,
  },
  {
    path: "*",
    element: <LandingPage />,
  },
  {
    path: "/geniusvoice",
    element: <Geniusvoice />,
  },
  {
    path: "/imgautox",
    element: <Imgautox />,
  },
  {
    path: "/login",
    element: <Login />,
  }
]);

function App() {
  return (
    <AuthProvider>
      <div className="min-h-screen bg-white">
        <RouterProvider router={router} />
      </div>
    </AuthProvider>
  );
}

export default App;