import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY || process.env.NEXT_PUBLIC_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export const generateProductDescription = async (formData) => {
  try {
    console.log('🔄 Iniciando geração...', formData);

    const prompt = `Create a ${formData.tone} product description for:
      Product: ${formData.productName}
      Features: ${formData.mainFeatures}
      Target Audience: ${formData.targetAudience}
      Price: ${formData.priceRange}

      The description should be engaging, highlight the key features, and speak directly to the target audience.
      Please write the description in Portuguese.`;

    const completion = await openai.chat.completions.create({
      model: "gpt-4-turbo-preview",
      messages: [
        {
          role: "system",
          content: "You are a professional product description writer. Create engaging, SEO-friendly descriptions that highlight key features and benefits while maintaining the specified tone. Focus on the target audience's needs and pain points."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      temperature: 0.7,
      max_tokens: 500
    });

    const description = completion.choices[0].message.content;
    console.log('✅ Descrição gerada com sucesso');
    return description;

  } catch (error) {
    console.error('❌ Erro na geração:', error);
    throw new Error('Falha ao gerar descrição');
  }
}; 