import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../Components/ui/card';
import { Button } from '../../Components/ui/button';
import { Input } from '../../Components/ui/input';
import { Alert, AlertDescription } from '../../Components/ui/alert';
import { Loader2, Wand2, Copy, Check, Sparkles, Target, Zap, Clock } from 'lucide-react';
import { generateProductDescription } from './description.service';

const ProductDescriptionGenerator = () => {
  const [formData, setFormData] = useState({
    productName: '',
    mainFeatures: '',
    targetAudience: '',
    priceRange: '',
    tone: 'professional'
  });

  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);

  const MAX_CHARS = 400;

  const generateDescription = async () => {
    console.log('🚀 Iniciando geração...', formData);
    setLoading(true);
    setError('');

    // Validação dos campos
    if (!formData.productName || !formData.mainFeatures || !formData.targetAudience || !formData.priceRange) {
      console.warn('❌ Campos obrigatórios não preenchidos');
      setError('Por favor, preencha todos os campos');
      setLoading(false);
      return;
    }

    try {
      const result = await generateProductDescription(formData);
      setDescription(result);
    } catch (err) {
      console.error('❌ Erro:', err);
      setError(err.message || 'Erro ao gerar descrição');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(description);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 p-6">
      <div className="max-w-6xl mx-auto space-y-8">
        <div className="text-center space-y-4">
          <h1 className="text-4xl font-bold text-gray-900">Descrição Smart Ecom</h1>
          <p className="text-xl text-gray-600">Crie descrições profissionais e persuasivas com IA</p>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
            <Card className="bg-white/80">
              <CardContent className="pt-6 text-center">
                <Sparkles className="h-8 w-8 mx-auto text-blue-500 mb-2" />
                <h3 className="font-semibold mb-1">Descrições Únicas</h3>
                <p className="text-sm text-gray-600">Textos originais e otimizados para SEO</p>
              </CardContent>
            </Card>

            <Card className="bg-white/80">
              <CardContent className="pt-6 text-center">
                <Target className="h-8 w-8 mx-auto text-green-500 mb-2" />
                <h3 className="font-semibold mb-1">Público-Alvo</h3>
                <p className="text-sm text-gray-600">Conteúdo direcionado ao seu cliente ideal</p>
              </CardContent>
            </Card>

            <Card className="bg-white/80">
              <CardContent className="pt-6 text-center">
                <Zap className="h-8 w-8 mx-auto text-yellow-500 mb-2" />
                <h3 className="font-semibold mb-1">Alta Conversão</h3>
                <p className="text-sm text-gray-600">Textos persuasivos que vendem mais</p>
              </CardContent>
            </Card>

            <Card className="bg-white/80">
              <CardContent className="pt-6 text-center">
                <Clock className="h-8 w-8 mx-auto text-purple-500 mb-2" />
                <h3 className="font-semibold mb-1">Economia de Tempo</h3>
                <p className="text-sm text-gray-600">Descrições prontas em segundos</p>
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          <div className="space-y-6">
            <Card>
              <CardHeader>
                <CardTitle>Informações do Produto</CardTitle>
                <p className="text-sm text-gray-600">
                  Preencha os detalhes abaixo para gerar uma descrição otimizada e persuasiva
                </p>
              </CardHeader>
              <CardContent className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Nome do Produto ({formData.productName.length}/{MAX_CHARS})
                  </label>
                  <Input
                    placeholder="Ex: Smartwatch X-Pro"
                    value={formData.productName}
                    onChange={(e) => {
                      if (e.target.value.length <= MAX_CHARS) {
                        setFormData({...formData, productName: e.target.value})
                      }
                    }}
                    maxLength={MAX_CHARS}
                  />
                  <p className="mt-1 text-xs text-gray-500">
                    Dica: Use um nome claro e memorável que destaque o principal benefício
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Características Principais ({formData.mainFeatures.length}/{MAX_CHARS})
                  </label>
                  <Input
                    placeholder="Ex: monitor cardíaco, tela AMOLED, bateria de longa duração"
                    value={formData.mainFeatures}
                    onChange={(e) => {
                      if (e.target.value.length <= MAX_CHARS) {
                        setFormData({...formData, mainFeatures: e.target.value})
                      }
                    }}
                    maxLength={MAX_CHARS}
                  />
                  <p className="mt-1 text-xs text-gray-500">
                    Dica: Liste 3-5 características mais importantes, separadas por vírgula
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Público-Alvo ({formData.targetAudience.length}/{MAX_CHARS})
                  </label>
                  <Input
                    placeholder="Ex: praticantes de esportes, 25-45 anos, focados em saúde"
                    value={formData.targetAudience}
                    onChange={(e) => {
                      if (e.target.value.length <= MAX_CHARS) {
                        setFormData({...formData, targetAudience: e.target.value})
                      }
                    }}
                    maxLength={MAX_CHARS}
                  />
                  <p className="mt-1 text-xs text-gray-500">
                    Dica: Descreva idade, interesses e necessidades do seu cliente ideal
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Faixa de Preço ({formData.priceRange.length}/{MAX_CHARS})
                  </label>
                  <Input
                    placeholder="Ex: R$ 499,90 em até 12x sem juros"
                    value={formData.priceRange}
                    onChange={(e) => {
                      if (e.target.value.length <= MAX_CHARS) {
                        setFormData({...formData, priceRange: e.target.value})
                      }
                    }}
                    maxLength={MAX_CHARS}
                  />
                  <p className="mt-1 text-xs text-gray-500">
                    Dica: Inclua o preço e condições especiais de pagamento
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Tom da Descrição
                  </label>
                  <select
                    className="w-full h-10 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.tone}
                    onChange={(e) => setFormData({...formData, tone: e.target.value})}
                  >
                    <option value="professional">Profissional - Formal e direto</option>
                    <option value="casual">Casual - Descontraído e amigável</option>
                    <option value="persuasive">Persuasivo - Focado em vendas</option>
                    <option value="luxury">Luxuoso - Sofisticado e exclusivo</option>
                    <option value="technical">Técnico - Detalhado e específico</option>
                  </select>
                  <p className="mt-1 text-xs text-gray-500">
                    Dica: Escolha o tom que melhor se conecta com seu público-alvo
                  </p>
                </div>

                <Button
                  className="w-full"
                  onClick={generateDescription}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Gerando...
                    </>
                  ) : (
                    <>
                      <Wand2 className="mr-2 h-4 w-4" />
                      Gerar Descrição
                    </>
                  )}
                </Button>
              </CardContent>
            </Card>

            <Card className="bg-blue-50/50 border-blue-100">
              <CardHeader>
                <CardTitle className="text-blue-700 text-lg">💡 Dicas para Resultados Incríveis</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="space-y-3 text-sm text-blue-700">
                  <li className="flex items-start gap-2">
                    <span className="font-bold">•</span>
                    <span>Seja específico nas características, evite termos genéricos</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="font-bold">•</span>
                    <span>Descreva seu público-alvo com detalhes demográficos e comportamentais</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="font-bold">•</span>
                    <span>Inclua informações de preço e condições especiais de pagamento</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="font-bold">•</span>
                    <span>Escolha um tom que combine com seu produto e público</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="font-bold">•</span>
                    <span>Revise e ajuste a descrição gerada para seu estilo único</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>

          <div className="space-y-6">
            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {description ? (
              <Card className="bg-white/50 backdrop-blur">
                <CardHeader className="flex flex-row items-center justify-between">
                  <CardTitle>Descrição Gerada</CardTitle>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={copyToClipboard}
                    className="flex items-center gap-2"
                  >
                    {copied ? (
                      <>
                        <Check className="h-4 w-4" />
                        Copiado!
                      </>
                    ) : (
                      <>
                        <Copy className="h-4 w-4" />
                        Copiar
                      </>
                    )}
                  </Button>
                </CardHeader>
                <CardContent>
                  <div className="p-4 bg-white rounded-lg border border-gray-200">
                    <p className="text-gray-800 whitespace-pre-wrap">{description}</p>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Card className="bg-gray-50 border-dashed border-2">
                <CardContent className="p-8 text-center text-gray-500">
                  <Wand2 className="h-12 w-12 mx-auto mb-4 opacity-50" />
                  <p>Preencha os campos ao lado e clique em "Gerar Descrição"</p>
                  <p className="text-sm mt-2">A descrição gerada aparecerá aqui</p>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDescriptionGenerator;