import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../Components/ui/card';
import { Button } from '../../Components/ui/button';
import { Input } from '../../Components/ui/input';
import { Alert, AlertDescription } from '../../Components/ui/alert';
import { Loader2, Download, Link, Code, FileDown } from 'lucide-react';

const StepCard = ({ icon: Icon, number, title, description, color }) => (
  <div className="relative p-6 bg-white rounded-2xl shadow-lg transform transition-all duration-200 hover:scale-105">
    <div className={`absolute -top-4 -left-4 w-12 h-12 ${color} rounded-xl shadow-lg flex items-center justify-center transform rotate-12 hover:rotate-0 transition-transform duration-200`}>
      <Icon className="w-6 h-6 text-white" />
    </div>
    <div className="ml-6 space-y-2">
      <div className={`text-sm font-semibold ${color.replace('bg-', 'text-')}`}>
        Passo {number}
      </div>
      <h3 className="text-xl font-bold text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const ElementorCloner = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const clonePage = async () => {
    try {
      setLoading(true);
      setError('');

      if (!url) {
        throw new Error('Por favor, insira uma URL válida');
      }

      // Usando um serviço de proxy CORS
      const proxyUrl = 'https://api.allorigins.win/raw?url=';
      const response = await fetch(proxyUrl + encodeURIComponent(url), {
        method: 'GET',
        headers: {
          'Content-Type': 'text/html',
        },
      });

      if (!response.ok) {
        throw new Error('Não foi possível acessar a página');
      }

      const html = await response.text();

      // Criar e fazer download do arquivo
      const blob = new Blob([html], { type: 'text/html' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'elementor-page.html';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 p-6">
      <div className="max-w-6xl mx-auto space-y-12">
        <div className="text-center space-y-2">
          <h1 className="text-4xl font-bold text-gray-900">Elementor para HTML</h1>
          <p className="text-xl text-gray-600">Transforme páginas do Elementor em HTML puro em segundos</p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 items-start">
          <div className="h-full flex flex-col justify-start space-y-8">
            <h2 className="text-3xl font-bold text-gray-800 text-center md:text-left">
              Como funciona?
            </h2>

            <div className="space-y-8">
              <StepCard
                icon={Link}
                number="1"
                title="Cole a URL"
                description="Insira o link da página do Elementor que deseja converter"
                color="bg-blue-500"
              />

              <StepCard
                icon={Code}
                number="2"
                title="Transformação"
                description="Nossa ferramenta converte automaticamente o conteúdo em HTML puro"
                color="bg-green-500"
              />

              <StepCard
                icon={FileDown}
                number="3"
                title="Download"
                description="Baixe o arquivo HTML pronto para uso"
                color="bg-purple-500"
              />
            </div>
          </div>

          <div className="h-full flex flex-col space-y-6">
            <Card className="bg-white shadow-2xl">
              <CardHeader className="space-y-1 pb-4 border-b">
                <CardTitle className="text-2xl font-bold text-center text-gray-800">
                  Comece agora
                </CardTitle>
                <p className="text-sm text-center text-gray-500">
                  Cole a URL e transforme sua página
                </p>
              </CardHeader>

              <CardContent className="space-y-6 pt-6">
                <div className="space-y-4">
                  <Input
                    type="url"
                    placeholder="https://seusite.com/pagina-elementor"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="w-full h-12 px-4 border-gray-200 focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>

                <Button
                  onClick={clonePage}
                  disabled={loading}
                  className="w-full h-12 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
                >
                  {loading ? (
                    <>
                      <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                      Transformando...
                    </>
                  ) : (
                    <>
                      <Download className="mr-2 h-5 w-5" />
                      Transformar e baixar HTML
                    </>
                  )}
                </Button>

                {error && (
                  <Alert variant="destructive" className="mt-4 bg-red-50 border-red-200">
                    <AlertDescription className="text-red-800">{error}</AlertDescription>
                  </Alert>
                )}
              </CardContent>
            </Card>

            <div className="bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl p-6 border border-blue-200 shadow-lg">
              <h3 className="font-bold text-blue-800 mb-4 text-lg flex items-center">
                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </svg>
                Dicas importantes
              </h3>
              <ul className="space-y-3">
                {[
                  'Certifique-se que a página está pública',
                  'Aguarde o carregamento completo',
                  'O arquivo HTML incluirá todo o conteúdo visível',
                  'Imagens e estilos serão preservados'
                ].map((tip, index) => (
                  <li key={index} className="flex items-center text-blue-700">
                    <div className="w-2 h-2 rounded-full bg-blue-400 mr-2" />
                    {tip}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElementorCloner;