import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY || process.env.NEXT_PUBLIC_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export const translateWord = async (word) => {
  try {
    console.log('🔄 Iniciando tradução...', word);

    const completion = await openai.chat.completions.create({
      model: "gpt-4-turbo-preview",
      messages: [
        {
          role: "system",
          content: "You are a translator. Respond only with the translated word, nothing else."
        },
        {
          role: "user",
          content: `Translate "${word}" to Portuguese.`
        }
      ],
      temperature: 0
    });

    const translation = completion.choices[0].message.content;
    console.log('✅ Tradução concluída:', translation);
    return translation;

  } catch (error) {
    console.error('❌ Erro na tradução:', error);
    throw new Error('Falha ao traduzir palavra');
  }
}; 