import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../Components/ui/card.jsx';
import { Button } from '../../Components/ui/button.jsx';
import { Alert, AlertDescription } from '../../Components/ui/alert.jsx';
import { Image, Loader2, Download, Wand2, SparklesIcon } from 'lucide-react';
import imgAutoxService from './Imgautox.service.js';

export default function ImageGenerator() {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState('');
  const [size, setSize] = useState('1024x1024');
  const [quality, setQuality] = useState('standard');
  const [style, setStyle] = useState('vivid');

  const handleGenerateImage = async () => {
    if (!prompt.trim()) {
      setError('Por favor, insira uma descrição');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'dall-e-3',
          prompt,
          n: 1,
          size,
          quality,
          style,
        }),
      });

      if (!response.ok) throw new Error('Falha ao gerar imagem');

      const data = await response.json();
      setImageUrl(data.data[0].url);
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao gerar imagem. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-pink-100 via-purple-100 to-indigo-100">
      <div className="max-w-6xl mx-auto p-6 space-y-8">
        {/* Header */}
        <div className="text-center space-y-4">
          <h1 className="text-5xl font-bold bg-gradient-to-r from-pink-600 to-purple-600 bg-clip-text text-transparent">
            Img Auto X
          </h1>
          <p className="text-xl text-gray-700">Transforme suas ideias em arte com IA</p>
        </div>

        {/* Criar Imagem e Preview */}
        <div className="grid md:grid-cols-2 gap-6">
          {/* Input Section */}
          <Card className="bg-white/90 backdrop-blur-sm shadow-xl">
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-purple-600">
                <Wand2 className="w-6 h-6" />
                Criar Imagem
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Tamanho
                </label>
                <select
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                  className="w-full h-10 px-3 border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500"
                >
                  <option value="1024x1024">1024x1024</option>
                  <option value="1024x1792">1024x1792 (Portrait)</option>
                  <option value="1792x1024">1792x1024 (Landscape)</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Qualidade
                </label>
                <select
                  value={quality}
                  onChange={(e) => setQuality(e.target.value)}
                  className="w-full h-10 px-3 border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500"
                >
                  <option value="standard">Standard</option>
                  <option value="hd">HD</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Estilo
                </label>
                <select
                  value={style}
                  onChange={(e) => setStyle(e.target.value)}
                  className="w-full h-10 px-3 border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500"
                >
                  <option value="vivid">Vivid</option>
                  <option value="natural">Natural</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Descrição da Imagem
                </label>
                <textarea
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  className="w-full h-32 px-3 py-2 border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500"
                  placeholder="Descreva a imagem que você quer criar..."
                />
              </div>

              {error && (
                <Alert variant="destructive">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              <Button
                onClick={handleGenerateImage}
                disabled={loading}
                className="w-full bg-gradient-to-r from-pink-600 to-purple-600 hover:from-pink-700 hover:to-purple-700"
              >
                {loading ? (
                  <div className="flex items-center justify-center gap-2">
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Gerando...
                  </div>
                ) : (
                  <>
                    <Wand2 className="mr-2 h-5 w-5" />
                    Gerar Imagem
                  </>
                )}
              </Button>
            </CardContent>
          </Card>

          {/* Preview Section */}
          <Card className="bg-white/90 backdrop-blur-sm shadow-xl">
            <CardHeader>
              <CardTitle>Preview da Imagem</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {imageUrl ? (
                <div className="space-y-4">
                  <div className="relative aspect-square rounded-xl overflow-hidden bg-purple-50">
                    <img
                      src={imageUrl}
                      alt="Generated"
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <Button
                    onClick={() => window.open(imageUrl)}
                    variant="outline"
                    className="w-full border-purple-200 hover:bg-purple-50"
                  >
                    <Download className="mr-2 h-4 w-4" />
                    Download
                  </Button>
                </div>
              ) : (
                <div className="aspect-square rounded-xl bg-purple-50 flex items-center justify-center">
                  <div className="text-center">
                    <Image className="h-12 w-12 mx-auto mb-4 text-purple-300" />
                    <p className="text-gray-500">Sua imagem aparecerá aqui</p>
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        </div>

        {/* Benefits - Movido para aqui */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            {
              icon: Wand2,
              title: "Alta Qualidade",
              desc: "Imagens em alta resolução até 1024x1024"
            },
            {
              icon: SparklesIcon,
              title: "Estilos Únicos",
              desc: "Múltiplos estilos e variações artísticas"
            },
            {
              icon: Image,
              title: "Resultados Rápidos",
              desc: "Geração de imagens em segundos"
            }
          ].map((benefit, i) => (
            <div key={i} className="bg-white/80 backdrop-blur-sm p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 border border-purple-100">
              <benefit.icon className="h-8 w-8 text-purple-600 mb-3" />
              <h3 className="font-bold text-lg text-gray-800 mb-2">{benefit.title}</h3>
              <p className="text-gray-600">{benefit.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}