// src/components/Translate.jsx
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Card, CardHeader, CardTitle, CardContent } from '../../Components/ui/card';
import { Button } from '../../Components/ui/button';
import { Input } from '../../Components/ui/input';
import { Alert, AlertDescription } from '../../Components/ui/alert';
import { Loader2, Languages, LogOut } from 'lucide-react';
import { translateWord } from './translate.service';

const Translate = () => {
  const { user, logout } = useAuth();
  const [word, setWord] = useState('');
  const [translation, setTranslation] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleTranslate = async () => {
    if (!word.trim()) {
      setError('Por favor, digite uma palavra');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const result = await translateWord(word.trim());
      setTranslation(result);
    } catch (err) {
      console.error('❌ Erro:', err);
      setError(err.message || 'Erro ao traduzir palavra');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !loading) {
      handleTranslate();
    }
  };

  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 p-6 flex items-center justify-center">
        <Card className="w-full max-w-md">
          <CardContent className="p-6 text-center">
            <Alert className="mb-4">
              <AlertDescription>
                Faça login para acessar o tradutor
              </AlertDescription>
            </Alert>
            <Button
              onClick={() => window.location.href = '/login'}
              className="bg-gradient-to-r from-blue-600 to-purple-600"
            >
              Ir para Login
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 p-6">
      <div className="max-w-xl mx-auto mb-4 flex justify-between items-center">
        <p className="text-gray-600">Bem-vindo, {user.email}</p>
        <Button
          onClick={logout}
          variant="outline"
          className="flex items-center gap-2"
        >
          <LogOut className="w-4 h-4" />
          Sair
        </Button>
      </div>

      <div className="max-w-xl mx-auto space-y-8">
        {/* Header */}
        <div className="text-center space-y-2">
          <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Tradutor Universal
          </h1>
          <p className="text-gray-600">Traduza palavras instantaneamente para qualquer idioma</p>
        </div>

        <Card className="backdrop-blur-sm bg-white/90 shadow-xl border-0">
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-blue-600">
              <Languages className="w-6 h-6" />
              Tradutor
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            {/* Input Group */}
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Palavra ou Frase
              </label>
              <Input
                placeholder="Digite o texto para traduzir..."
                value={word}
                onChange={(e) => setWord(e.target.value)}
                onKeyPress={handleKeyPress}
                disabled={loading}
                className="h-12 border-blue-100 focus:border-blue-300 focus:ring-blue-200"
              />
            </div>

            <Button
              className="w-full h-12 bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 transition-all duration-300"
              onClick={handleTranslate}
              disabled={loading || !word.trim()}
            >
              {loading ? (
                <>
                  <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                  Traduzindo...
                </>
              ) : 'Traduzir'}
            </Button>

            {error && (
              <Alert variant="destructive" className="border-red-200 bg-red-50">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {translation && (
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Tradução
                </label>
                <div className="p-4 bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg border border-blue-100">
                  <p className="text-gray-900 font-medium">{translation}</p>
                </div>
              </div>
            )}
          </CardContent>
        </Card>

        {/* Features */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {[
            {
              title: "Rápido",
              desc: "Traduções instantâneas"
            },
            {
              title: "Preciso",
              desc: "Alta precisão nas traduções"
            },
            {
              title: "Universal",
              desc: "Suporte a múltiplos idiomas"
            }
          ].map((feature, i) => (
            <div key={i} className="bg-white/80 backdrop-blur-sm p-4 rounded-xl shadow-sm border border-blue-50">
              <h3 className="font-semibold text-blue-600 mb-1">{feature.title}</h3>
              <p className="text-sm text-gray-600">{feature.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Translate;